<template>
  <div>
    <el-form :rules="rules"
             v-loading="loading"
             element-loading-text="正在登录..."
             element-loading-spinner="el-icon-loading"
             ref="loginForm" :model="loginForm" class="loginCss">
      <h3 class="loginTitle">短视频矩阵系统</h3>
      <el-form-item prop="username">
        <el-input type="text" prefix-icon="el-icon-user" auto-complete="false" v-model="loginForm.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" prefix-icon="el-icon-lock" show-password auto-complete="false" v-model="loginForm.password" placeholder="请输入密码"></el-input>
      </el-form-item>
<!--      <el-form-item>-->
<!--        <el-input type="text" auto-complete="false" v-model="loginForm.code" placeholder="点击图片更换验证码" style="width: 200px;margin-right: 5px"></el-input>-->
<!--        <img :src="captchaUrl">-->
<!--      </el-form-item>-->
      <el-checkbox v-model="checked" style="margin: 0px 0px 15px 0px;text-align: left">记住密码</el-checkbox>
      <el-button type="primary" style="width: 100%" @click="submitLogin">登录</el-button>
    </el-form>
  </div>
</template>

<script>

import {postRequest} from "@/utils/request";

export default {
  name: "Login",
  data(){
    return{
      captchaUrl:'',
      loginForm:{
        username:'admin',
        password:'123456',
      },
      loading:false,
      checked:true,
      rules:{
        username:[{required:true,message:"请输入用户名",trigger:'blur'}],
        password: [{required:true,message:"请输入密码",trigger:'blur'}]
      }
    }
  },
  methods:{
    submitLogin(){
      this.$refs['loginForm'].validate((valid)=>{
        if(valid){
          this.loading=true;
          this.request.post("user/login",this.loginForm).then(res=>{
            if(res){
              this.loading=false;
              if(res.code==='200'){
                localStorage.setItem("userInfo",JSON.stringify(res.data))
                this.$message.success('登录成功');
                this.$router.replace('account/account_auth')
              }else{
                this.$message.error(res.msg);
              }
            }
          });
        }else{
          return false;
        }
      })

    }
  }
}
</script>

<style scoped>
  .loginCss {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 180px auto;
    width: 350px;
    padding: 15px 35px 15px 35px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
  }
  .loginTitle{
    margin: 0px auto 40px auto;
    text-align: center;
    font-size: 20px;
  }
</style>